<template>
    <div>
        <van-nav-bar title="整改详情" left-text="返回" left-arrow @click-left="$router.go(-1)" />
        <van-cell-group title="整改信息">
            <van-cell title="检查类型" :value="model.TYPE_NAME" />
            <van-cell title="检查位置" :value="model.LOCATION" />
            <van-cell  v-if="model.STATUS != 0" title="存在问题" :value="model.PROBLEM" />

            <van-grid direction="horizontal" :column-num="2" v-for="(item, index) in model.ITEM_LIST" :key="index">
                <van-grid-item>
                    <span>{{item.NAME}}</span>
                </van-grid-item>
                <van-grid-item>
                    <van-radio-group v-model="item.STATUS" direction="horizontal" disabled>
                        <van-radio :name="0">合格</van-radio>
                        <van-radio :name="1">整改</van-radio>
                    </van-radio-group>
                </van-grid-item>
            </van-grid>
        </van-cell-group>
        <template v-if="model.STATUS == 2">
            <van-cell-group title="整改说明">
                <van-field v-model="model.RECTIFY_DESC" rows="1" autosize type="textarea" placeholder="请输入整改说明"
                    disabled />
            </van-cell-group>

            <van-cell-group title="整改图片">
                <van-image width="100" height="100" :src="item.Value" v-for="(item,index) in model.RECTIFY_IMAGE_KVS"
                    :key="index" style="margin: 10px 0px 0px 10px" />
            </van-cell-group>

            <van-cell-group title="签名">
                <img class="sign-image" v-if="model.SIGN_KV" :src="model.SIGN_KV.Value" alt="">
            </van-cell-group>
            <van-cell-group>
                <van-cell :title="'整改人：'+model.RECTIFY_NAME" :value="'整改日期：'+model.RECTIFY_DATE" />
            </van-cell-group>
        </template>
        <div style="margin: 15px">
            <van-button plain type="default" size="large" icon="cross" block @click="$router.go(-1)">返回</van-button>
        </div>

    </div>
</template>

<script>


    export default {
        data() {
            return {
                id: this.$route.query.id,
                model: {
                    RECTIFY_NAME: "",
                    RECTIFY_DATE: ""
                },
            }
        },
        created() {
            this.getModel();
        },

        methods: {
            getModel() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/GetModel",
                    data: { id: this.id },
                    completed: function (res) {
                        // console.log(res)
                        self.model = res.DATA
                    },
                });
            }
        }
    }
</script>
<style scoped>
    .sign-image {
        margin-top: 10px;
        display: block;
        margin: 0 auto;
        width: 300px;
        height: 120px;
    }
</style>